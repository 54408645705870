import HomeApi from '@/apis/homeApi';
import { defineStore } from 'pinia';
import { AppState } from './types';

export const useAppStore = defineStore(
  // 唯一ID
  'app',
  {
    state: () => ({
      weixinPic: '',
      wechatPic: '',
      wow: undefined
    }),
    getters: {},
    actions: {
      setAppInfo(partial: Partial<AppState>) {
        this.$patch(partial);
      },
      async getPic() {
        const result = await HomeApi.picList('3,4');
        const app: AppState = { weixinPic: '', wechatPic: '' };
        for (let index = 0; index < result.length; index++) {
          const pic = result[index];
          if (pic.type == 3) {
            app.weixinPic = pic.picPath;
          }
          if (pic.type == 4) {
            app.wechatPic = pic.picPath;
          }
        }
        this.setAppInfo(app);
      }
    },
    // 持久化
    persist: {
      key: `${import.meta.env.VITE_APP_STORE_PREFIX}_app`,
      storage: localStorage
    }
  }
);
