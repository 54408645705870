import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { useAppStore } from './modules/app';
import { useSharedWorkerStore } from './modules/sharedWorker';
import { useUserStore } from './modules/user';
import { useWSStore } from './modules/webscoket';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export { useAppStore, useUserStore, useSharedWorkerStore, useWSStore };
export default pinia;
