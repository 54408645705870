


export const layouts = {
'index': () => import('/D:/project/xa/sfa-sx-isoc/sfa-client/src/layout/index.vue'),
'MinLayout': () => import('/D:/project/xa/sfa-sx-isoc/sfa-client/src/layout/MinLayout.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'index'],
      children: [ {...route, path: ''} ],
    }
  })
}
