import SharedWorker from '@/assets/js/sharedworker.js?sharedworker';
import { getWsUrl } from '@/utils';
import mitt from 'mitt';
import { defineStore } from 'pinia';
import { useUserStore } from '../user';

const WSURL = getWsUrl();

export const useSharedWorkerStore = defineStore('sharedWorker', {
  state: () => ({
    sharedWorker: null,
    emitter: mitt()
  }),
  getters: {
    user() {
      const useStore = useUserStore();
      return useStore;
    }
  },
  actions: {
    init() {
      if (!window.SharedWorker) {
        throw new Error('当前浏览器不支持SharedWorker');
      }
      this.sharedWorker = new SharedWorker();
      // 定义接收方法
      this.sharedWorker.port.onmessage = (e) => {
        const { data } = e;
        if (data.type == 'login') {
          this.user.setInfo(data.result);
        } else if (data.type == 'logout') {
          this.user.resetInfo();
        } else if (data.type == 'messageCount') {
          // 站内信未读数量
          this.user.noReadMessageCount = data.noReadCount;
        } else if (data.type == 'stationMsg') {
          // 站内信
          this.user.noReadMessageCount++;
          this.emitter.emit('receiveStationMsg', data);
        }
      };
      this.connectWs();
      // 页面关闭
      window.onbeforeunload = () => {
        this.sendMessage({ type: 'portClose' });
      };
    },
    sendMessage(data) {
      this.sharedWorker.port.postMessage(data);
    },
    sendWsMessage(data) {
      this.sharedWorker.port.postMessage({ type: 'wsMessage', message: data });
    },
    connectWs() {
      if (!this.user.isLogin) {
        this.user.noReadMessageCount = 0;
        return;
      }
      const wsUrl = `${WSURL}/message/${this.user.id}?token=${this.user.token}`;
      this.sendMessage({ type: 'wsOpen', url: wsUrl });
    },
    closeWs() {
      this.sendMessage({ type: 'WsClose' });
    }
  }
});
