import { get, post } from '@/utils/http';
/**
 * 首页接口
 */

const HomeApi = {
  tabList: (data: { menuId: string; title: string; pageNum: Number; pageSize: Number }) => post<any>('release/list', data),
  listDetail: (data: { id: string }) => get<any>('release/details', data),
  picList: (type: string) => get<any>('home/picList', { type }),
  memberList: (params) => get<any>('member/infoList', params),
  getRecentArticlesList: (params) => get<any>('release/getRecentArticlesList', params),
  getFriendshipLink: (params) => get<any>('home/getFriendshipLink', params),
  getQuestion: (params) => get<any>('admin/user/getQuestion', params),
  getPhotoByType: (params) => get<any>('settingmanage/photo/getPhotoByType', params),
  recordClickNum: (data: { newsId: string }) => get<any>('memberService/recordClickNum', data)
};

export default HomeApi;
