import { get, post } from '@/utils/http';
/**
 * 用户相关接口
 */
enum URL {
  login = '/user/login',
  smsCode = '/user/smsCode',
  applyJoin = '/user/applyJoin',
  changePwd = '/user/changePwd'
}

const UserApi = {
  login: (data: { phone: string; password?: string; smsCode?: string }) => post<any>(URL.login, data),
  /**
   * 发送验证码
   * @param mobile 手机号
   * @param type 登录1
   */
  sendSmsCode: (phone: string, type: number) => get<any>(URL.smsCode, { phone, type }),
  applyJoin: (params: any) => post<any>(URL.applyJoin, params),
  changePwd: (data: { phone: string; password: string; smsCode: string }) => post<any>(URL.changePwd, data)
};

export default UserApi;
